import React, { useEffect, useMemo, useState } from 'react';
import { Dialog } from 'ui-kit';
import { IndustryModelForm } from './IndustryModelForm';
import { useFormik } from 'formik';
import { CreateIndustryModalType, IndustryModal } from '../types';
import { CreateIndustryModelFormSchema, EditIndustryModelFormSchema } from './schema';
import { createIndustryModal, editIndustryModel, getIndustryModalById } from '../services';
import { isEmpty } from 'lodash';
import { notify } from 'store';
import { useSelector } from 'react-redux';
import { selectIndustries } from '../../Companies/state/selectors';

interface UploadIndustryModalProps {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
  id?: number;
  mode: 'edit' | 'create';
}

export const IndustryModelModal = ({
  isOpen,
  handleClose,
  onSuccess,
  id,
  mode
}: UploadIndustryModalProps) => {
  const industries = useSelector(selectIndustries);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndustryModel, setCurrentIndustryModel] = useState<IndustryModal | undefined>();
  const [initialValues, setInitialValues] = useState<CreateIndustryModalType>({
    file: undefined,
    description: undefined,
    industry: undefined,
    latestQuarter: undefined,
    active: true,
    companies: []
  });

  useEffect(() => {
    const fetchModel = async () => {
      if (isOpen && id && mode === 'edit') {
        setIsLoading(true);
        const industryModel = await getIndustryModalById(id);
        setCurrentIndustryModel(industryModel);
        setIsLoading(false);
        const selected = industries.find(
          (industry) =>
            industry.sector_name.toLowerCase() === industryModel?.industry?.toLowerCase()
        );
        setInitialValues({
          file: undefined,
          description: industryModel?.description,
          industry: selected
            ? { value: `${selected.sector}`, label: selected.sector_name }
            : undefined,
          latestQuarter: industryModel?.latestQuarter,
          active: industryModel?.active ?? true,
          companies: industryModel?.companies ?? []
        });
      }
    };
    fetchModel();
  }, [isOpen, id]);

  const formik = useFormik<CreateIndustryModalType>({
    validationSchema:
      mode === 'create' ? CreateIndustryModelFormSchema : EditIndustryModelFormSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let success = false;
      if (values.industry && values.latestQuarter) {
        setIsLoading(true);
        if (mode === 'create') {
          const id = await createIndustryModal(
            values.industry?.label,
            values.active,
            values.latestQuarter,
            values.file,
            values.description,
            values.companies?.map((c) => c.id)
          );
          success = !isEmpty(id);
        } else if (mode === 'edit' && currentIndustryModel) {
          success = await editIndustryModel(
            currentIndustryModel.id,
            values.active,
            values.industry.label,
            values.latestQuarter,
            values.description,
            values.file,
            values.companies?.map((c) => c.id)
          );
        }

        setIsLoading(false);
        if (success) {
          onSuccess();
          cleanUpAndClose();
          notify({
            message: `industry model ${mode === 'edit' ? 'edited' : 'created'}  successfully`,
            severity: 'success',
            open: true
          });
        } else {
          notify({
            message: `failed to ${mode === 'edit' ? 'edited' : 'created'} industry model`,
            severity: 'error',
            open: true
          });
        }
      }
    }
  });

  const cleanUpAndClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={`${mode === 'edit' ? 'Edit' : 'Create'} Industry Model`}
      handleClose={cleanUpAndClose}
      width={'sm'}
      hideDialogActions={false}
      minHeight={'50vh'}
      isLoading={isLoading}
      isSaveDisabled={!formik.isValid}
      handleOk={formik.submitForm}
    >
      {!isLoading && (
        <IndustryModelForm
          formik={formik}
          currentIndustryModel={currentIndustryModel}
          mode={mode}
        />
      )}
    </Dialog>
  );
};
