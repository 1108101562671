import React from 'react';
import EventBus, { closeBus } from '../EventBus';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { NotificationType } from './types';

export default function (notification: NotificationType) {
  const { breakLine, message, severity, ...rest } = notification;
  const key = Date.now();

  EventBus?.notice({
    key: key,
    duration: null,
    content: (
      <Snackbar
        {...rest}
        onClose={() => closeBus(key)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
      >
        <Alert
          style={{ whiteSpace: breakLine ? 'pre' : 'normal' }}
          onClose={() => closeBus(key)}
          severity={severity}
        >
          {message}
        </Alert>
      </Snackbar>
    )
  });
}
