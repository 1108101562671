import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_COOKIE_NAME, CSRFTOKEN } from '../constants';
import { logout, USE_SSO_LOGIN } from '../auth.utils';

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_BACKEND_URL,
  withCredentials: true
});

const requestHandler = (request: AxiosRequestConfig) => {
  const csrfToken = Cookies.get(CSRFTOKEN);
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  const headers: { 'X-CSRFToken'?: string; Authorization?: string; Cookie?: string } = {};

  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }

  if (!USE_SSO_LOGIN && accessToken) {
    headers.Authorization = `JWT ${accessToken}`;
  }

  request.headers = headers;
  return request;
};

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(error);
    if ([401].includes(error?.response?.status)) {
      logout();
    }
    if ([403].includes(error?.response?.status)) {
      return Promise.reject(error?.response?.data?.details ?? 'Permission denied');
    }

    return Promise.reject(error?.response?.data?.details ?? 'Something Went Wrong');
  }
);

customAxios.interceptors.request.use((request) => requestHandler(request));

export default customAxios;
export const generateCancelToken = () => {
  return axios.CancelToken.source();
};
export const cancelRequest = (token: CancelTokenSource) => {
  token?.cancel('460');
};
export type { CancelTokenSource as CancelTokenSource } from 'axios';
