import { Axios, gql, GraphQL } from 'core';
import { PaginatedSnapshotModel, ModelTypeKeys, ModelSnapshotTypes } from './types';

export const getSnapshotModels = async (
  page: number,
  pageSize: number,
  filters: { [key: string]: string }
) => {
  const newFilters = {
    noSeparateAnnuals: false,
    noApiSnapshot: false,
    companyName: filters.companyName,
    companyTicker: filters.companyTicker
  };
  if (!!filters.qqqqFySnapshotLogStatus) {
    const qqqqFySnapshotLogStatus = filters.qqqqFySnapshotLogStatus === 'true';
    newFilters.noSeparateAnnuals = !qqqqFySnapshotLogStatus;
    newFilters.noApiSnapshot = qqqqFySnapshotLogStatus;
  } else if (!!filters.apiSnapshotLogStatus) {
    const apiSnapshotLogStatus = filters.apiSnapshotLogStatus === 'true';
    newFilters.noSeparateAnnuals = apiSnapshotLogStatus;
    newFilters.noApiSnapshot = !apiSnapshotLogStatus;
  }

  const response = await GraphQL.query({
    query: gql`
      query missingSnapshotModels(
        $companyTicker: String
        $companyName: String
        $page: Int!
        $pageSize: Int!
        $noSeparateAnnuals: Boolean
        $noApiSnapshot: Boolean
      ) {
        missingSnapshotModels(
          companyTicker: $companyTicker
          companyName: $companyName
          page: $page
          pageSize: $pageSize
          noSeparateAnnuals: $noSeparateAnnuals
          noApiSnapshot: $noApiSnapshot
        ) {
          excelModels {
            id
            company {
              id
              name
              ticker
            }
            createdAt
            updatedAt
            clientView
            finalized
            annualReady
            updateReady
            reviewed
            apiSnapshot
            apiSnapshotLog {
              status
              taskId
              taskType
              message
              updatedAt
              id
            }
            qqqqFySnapshot
            separateAnnualsLog {
              status
              taskType
              updatedAt
              message
              id
            }
          }
          totalObjects
        }
      }
    `,
    variables: { ...newFilters, page: page + 1, pageSize },
    fetchPolicy: 'no-cache'
  });
  const {
    data: { missingSnapshotModels }
  } = response as {
    data: { missingSnapshotModels: PaginatedSnapshotModel };
  };
  return {
    entities: missingSnapshotModels.excelModels,
    totalCount: missingSnapshotModels?.totalObjects
  };
};

export const generateSeparateAnnualFile = async (modelId: string) => {
  const response = await GraphQL.mutate({
    mutation: gql`
      mutation generateSeparateAnnualsFile($modelId: String!) {
        generateSeparateAnnualsFile(modelId: $modelId) {
          success
        }
      }
    `,
    variables: { modelId }
  });
  const {
    data: {
      generateSeparateAnnualsFile: { success }
    }
  } = response as {
    data: { generateSeparateAnnualsFile: { success: boolean } };
  };
  return success;
};

export const generateApiSnapshot = async (modelId: string) => {
  const response = await GraphQL.mutate({
    mutation: gql`
      mutation generateApiSnapshot($modelId: String!) {
        generateApiSnapshot(modelId: $modelId) {
          success
        }
      }
    `,
    variables: { modelId }
  });
  const {
    data: {
      generateApiSnapshot: { success }
    }
  } = response as {
    data: { generateApiSnapshot: { success: boolean } };
  };
  return success;
};
export const fetchTokenAndDownloadModel = (modelId: number, modelType: ModelTypeKeys) => {
  void GraphQL.mutate({
    mutation: gql`
      mutation fetchModelDownloadToken($modelId: String!, $modelType: String!) {
        generateModelDownloadToken(modelId: $modelId, modelType: $modelType) {
          token
        }
      }
    `,
    variables: {
      modelId,
      modelType
    }
  }).then(({ data: { generateModelDownloadToken } }) => {
    if (generateModelDownloadToken) {
      const { token } = generateModelDownloadToken as { token: string };

      if (token && window && process.env.REACT_APP_INTERNAL_BACKEND_URL) {
        window.location.assign(
          `${process.env.REACT_APP_INTERNAL_BACKEND_URL}/excel?x-signed-token=${token}`
        );
      }
    }
  });
};

export const downloadModalSnapshot = async (modelId: number, modelType: ModelSnapshotTypes) => {
  const res = await Axios.get<{ url: string; filename: string }>(
    `download_model_snapshot?model_id=${modelId}&type=${modelType}`
  );
  if (res.status == 200) {
    const { filename, url } = res.data;
    const link = document.createElement('a');
    document.body.append(link);
    link.download = filename;
    link.href = url;
    link.click();
    link.parentNode?.removeChild(link);
  }
};
