import { AccessMode, UserCompanyAccesses, UserTier } from './types';
import { Axios, gql, GraphQL } from 'core';

export const getUserCompanyAccesses = async (
  userId: string,
  filters: { [key: string]: string }
) => {
  const response: { data: { userAccessesCompany: UserCompanyAccesses[] } } = await GraphQL.query({
    context: {
      clientFacing: true
    },
    query: gql`
      query userAccessesCompany($userId: String!, $ticker: String) {
        userAccessesCompany(userId: $userId, ticker: $ticker) {
          id
          company {
            ticker
            name
          }
          accessMode
          comment
        }
      }
    `,
    variables: {
      userId,
      ...filters
    },
    fetchPolicy: 'no-cache'
  });
  const {
    data: { userAccessesCompany }
  } = response;
  return {
    totalCount: userAccessesCompany.length,
    entities: userAccessesCompany
  };
};

export const deleteUserCompanyAccess = async (id: string) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation deleteUserAccessesCompany($id: String!) {
        deleteUserAccessesCompany(id: $id) {
          id
          success
          errors
        }
      }
    `,
    variables: {
      id
    }
  });
  const {
    data: {
      deleteUserAccessesCompany: { error, success }
    }
  } = response as {
    data: { deleteUserAccessesCompany: { error: string; success: boolean } };
  };
  return { error, success };
};

export const deleteAllUserCompanyAccess = async (userId: string) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation deleteAllUserAccessesCompany($userId: String!) {
        deleteAllUserAccessesCompany(userId: $userId) {
          success
          errors
        }
      }
    `,
    variables: {
      userId
    }
  });
  const {
    data: {
      deleteAllUserAccessesCompany: { errors, success }
    }
  } = response as {
    data: { deleteAllUserAccessesCompany: { errors: string[]; success: boolean } };
  };
  return { errors, success };
};

export const createMultipleUserCompanyAccess = async (
  companyIds: number[],
  userId: string,
  accessMode: AccessMode
) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation createMultipleUserAccessesCompany(
        $accessMode: String!
        $companyIds: [String!]
        $userId: String!
      ) {
        createMultipleUserAccessesCompany(
          accessMode: $accessMode
          companyIds: $companyIds
          userId: $userId
        ) {
          success
          errors
        }
      }
    `,
    variables: {
      companyIds,
      userId,
      accessMode
    }
  });
  const {
    data: {
      createMultipleUserAccessesCompany: { errors, success }
    }
  } = response as {
    data: { createMultipleUserAccessesCompany: { errors: string[]; success: boolean } };
  };
  return { errors, success };
};

export const resetPassword = async (email: string): Promise<boolean> => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation resetUserPassword($email: String!) {
        resetUserPassword(email: $email) {
          success
        }
      }
    `,
    variables: {
      email
    },
    fetchPolicy: 'no-cache'
  });
  const {
    data: {
      resetUserPassword: { success }
    }
  } = response as { data: { resetUserPassword: { success: boolean } } };
  return success;
};

export const disableUsers = async (userIds: string[]): Promise<boolean> => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation disableUsers($userIds: [String]!) {
        disableUsers(userIds: $userIds) {
          success
        }
      }
    `,
    variables: { userIds }
  });
  const {
    data: {
      disableUsers: { success }
    }
  } = response as { data: { disableUsers: { success: boolean } } };
  return success;
};

export const enableUsers = async (userIds: string[]): Promise<boolean> => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation enableUsers($userIds: [String]!) {
        enableUsers(userIds: $userIds) {
          success
        }
      }
    `,
    variables: { userIds }
  });
  const {
    data: {
      enableUsers: { success }
    }
  } = response as { data: { enableUsers: { success: boolean } } };
  return success;
};

export const createUser = async (email: string, password: string, tier: UserTier) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation createNewUser($email: String!, $password: String!, $tier: String!) {
        createNewUser(email: $email, password: $password, tier: $tier) {
          success
          errors
        }
      }
    `,
    variables: {
      email,
      password,
      tier
    }
  });
  const {
    data: {
      createUser: { errors, success }
    }
  } = response as { data: { createUser: { success: boolean; errors: string[] } } };
  return { success, errors };
};

export const updateUserTier = async (userId: string, tier: string) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation updateUserTier($userId: String!, $tier: String!) {
        updateUserTier(userId: $userId, tier: $tier) {
          success
          error
        }
      }
    `,
    variables: {
      userId,
      tier
    }
  });
  const {
    data: {
      updateUserTier: { error, success }
    }
  } = response as { data: { updateUserTier: { success: boolean; error: string } } };
  return { success, error };
};

export const updateUserType = async (userId: string, type: string) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation updateUserType($userId: String!, $type: String!) {
        updateUserType(userId: $userId, userType: $type) {
          success
          error
        }
      }
    `,
    variables: {
      userId,
      type
    }
  });
  const {
    data: {
      updateUserType: { error, success }
    }
  } = response as { data: { updateUserType: { success: boolean; error: string } } };
  return { success, error };
};

export const exportUsers = async (): Promise<string> => {
  const response = await Axios.get('golden_gate/users/export');
  return response.data as string;
};

export const createPreAccount = async (
  email: string,
  userTier: string,
  companyId: string,
  userType: string,
  productType: string
) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation createPreAccount(
        $companyId: String!
        $email: String!
        $userTier: String!
        $productType: String!
        $userType: String
      ) {
        preAccount: createPreAccount(
          companyId: $companyId
          email: $email
          userTier: $userTier
          productType: $productType
          userType: $userType
        ) {
          preAccount {
            hashCode
          }
          error
        }
      }
    `,
    variables: {
      email,
      companyId,
      userTier,
      userType,
      productType
    }
  });
  const { data } = response as {
    data: {
      preAccount?: {
        error?: string;
        preAccount: {
          hashCode: string;
        };
      };
    };
  };
  return { hashCode: data.preAccount?.preAccount?.hashCode ?? '', error: data?.preAccount?.error };
};
export const bulkCreatePreAccount = async (
  emails: string[],
  userTier: string,
  companyId: string,
  userType: string,
  productType: string
) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation BulkCreatePreAccount(
        $companyId: String!
        $emailList: [String!]!
        $userTier: String!
        $productType: String!
        $userType: String
      ) {
        response: bulkCreatePreAccount(
          companyId: $companyId
          emailList: $emailList
          userTier: $userTier
          productType: $productType
          userType: $userType
        ) {
          errors
          success
        }
      }
    `,
    variables: {
      emailList: emails,
      companyId,
      userTier,
      userType,
      productType
    }
  });
  const { data } = response as {
    data: {
      response?: {
        errors?: string[];
        success: boolean;
      };
    };
  };

  const errors = (data.response?.errors ?? []).map(
    (error) => JSON.parse(error.replace(/'/g, '"')) as { email: string; reason: string }
  );

  return { errors, success: data.response?.success ?? false };
};

export const updateUser = async (
  userId: string,
  tier: string,
  userType: string,
  active?: boolean,
  productType?: string,
  marketplace_status?: string,
  daily_download_limit?: number,
  total_download_limit?: number
) => {
  const response = await GraphQL.mutate({
    context: {
      clientFacing: true
    },
    mutation: gql`
      mutation updateUser(
        $userId: String!
        $tier: String!
        $userType: String
        $active: Boolean
        $productType: String
        $marketplace_status: String
        $daily_download_limit: Int
        $total_download_limit: Int
      ) {
        updateUser(
          userId: $userId
          tier: $tier
          userType: $userType
          active: $active
          productType: $productType
          marketplaceStatus: $marketplace_status
          dailyDownloadLimit: $daily_download_limit
          totalDownloadLimit: $total_download_limit
        ) {
          success
          error
        }
      }
    `,
    variables: {
      userId,
      tier,
      userType,
      active,
      productType,
      marketplace_status,
      daily_download_limit,
      total_download_limit
    }
  });
  const {
    data: {
      updateUser: { error, success }
    }
  } = response as { data: { updateUser: { success: boolean; error: string } } };
  return { success, error };
};
