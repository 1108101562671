import { gql, GraphQL } from 'core';
import { IndustryModal } from './types';
import industryModels from './IndustryModels';
export const getIndustryModals = async (query?: string) => {
  const response = await GraphQL.query<{ industryModels: IndustryModal[] }>({
    query: gql`
      query industryModels($query: String) {
        industryModels(query: $query) {
          id
          active
          createdAt
          description
          industry
          latestQuarter
          updatedAt
          uploadedBy {
            email
          }
        }
      }
    `,
    variables: {
      query
    },
    fetchPolicy: 'no-cache'
  });
  const {
    data: { industryModels }
  } = response;
  return { entities: industryModels, totalCount: industryModels.length };
};

export const getIndustryModalById = async (id: number) => {
  const response = await GraphQL.query<{ industryModel: IndustryModal }>({
    query: gql`
      query industryModel($id: String) {
        industryModel(id: $id) {
          id
          active
          createdAt
          description
          industry
          latestQuarter
          updatedAt
          companies {
            company {
              id
              ticker
            }
          }
        }
      }
    `,
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });
  const {
    data: { industryModel }
  } = response;
  industryModel.companies = industryModel.companies?.map((c: any) => c.company);
  return industryModel;
};

export const editIndustryModel = async (
  id: number,
  active: boolean,
  industry: string,
  latestQuarter: string,
  description?: string,
  file?: File,
  companyIds?: number[]
) => {
  const response = await GraphQL.mutate<{ editIndustryModel: { success: boolean } }>({
    mutation: gql`
      mutation(
        $id: String!
        $active: Boolean
        $industry: String
        $description: String
        $latestQuarter: String
        $file: Upload
        $companyIds: [String]
      ) {
        editIndustryModel(
          id: $id
          active: $active
          industry: $industry
          description: $description
          latestQuarter: $latestQuarter
          file: $file
          companyIds: $companyIds
        ) {
          success
        }
      }
    `,
    variables: {
      id,
      active,
      industry,
      description,
      latestQuarter,
      file,
      companyIds
    },
    fetchPolicy: 'no-cache'
  });
  return response.data?.editIndustryModel?.success ?? false;
};

export const createIndustryModal = async (
  industry: string,
  active: boolean,
  latestQuarter: string,
  file?: File,
  description?: string,
  companyIds?: number[]
) => {
  const response = await GraphQL.mutate<{ uploadIndustryModel: { id: string } }>({
    mutation: gql`
      mutation(
        $file: Upload!
        $industry: String!
        $active: Boolean
        $description: String
        $latestQuarter: String
        $companyIds: [String]
      ) {
        uploadIndustryModel(
          file: $file
          industry: $industry
          active: $active
          description: $description
          latestQuarter: $latestQuarter
          companyIds: $companyIds
        ) {
          id
        }
      }
    `,
    variables: {
      file,
      active,
      industry,
      description,
      latestQuarter,
      companyIds
    },
    fetchPolicy: 'no-cache'
  });
  return response.data?.uploadIndustryModel?.id;
};

export const downloadIndustryModal = async (modelId: number) => {
  const response = await GraphQL.mutate<{ generateModelDownloadToken: { token: string } }>({
    mutation: gql`
      mutation fetchModelDownloadToken($modelId: String!, $modelType: String!) {
        generateModelDownloadToken(modelId: $modelId, modelType: $modelType) {
          token
        }
      }
    `,
    variables: {
      modelId,
      modelType: 'industry_model'
    }
  });

  if (response && response.data) {
    const {
      generateModelDownloadToken: { token }
    } = response.data;
    if (token && process.env.REACT_APP_INTERNAL_BACKEND_URL) {
      window.open(
        `${process.env.REACT_APP_INTERNAL_BACKEND_URL}/excel?x-signed-token=${token}`,
        '_blank'
      );
    }
  }
};
