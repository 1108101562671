import * as Yup from 'yup';

export const CreateIndustryModelFormSchema = Yup.object().shape({
  description: Yup.string(),
  industry: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required()
    })
    .required(),
  latestQuarter: Yup.string().required(),
  active: Yup.boolean().required(),
  file: Yup.mixed().required('File is required'),
  companies: Yup.array()
    .optional()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        ticker: Yup.string()
      })
    )
});

export const EditIndustryModelFormSchema = Yup.object().shape({
  description: Yup.string(),
  industry: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required()
    })
    .required(),
  latestQuarter: Yup.string(),
  active: Yup.boolean(),
  file: Yup.mixed(),
  companies: Yup.array()
    .optional()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        ticker: Yup.string()
      })
    )
});
