import { ComboBox, Dialog, OptionType } from 'ui-kit';
import { styled } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import React, { useState, useRef } from 'react';
import { useFormik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import { UserTier, UserType, User } from '../types';
import {
  PRODUCT_TYPE_OPTIONS,
  USER_STATUS_OPTIONS,
  USER_TIER_OPTIONS,
  USER_TYPE_OPTIONS
} from '../constants';
import { bulkCreatePreAccount, createPreAccount, updateUser } from '../services';
import { FormikType, TickerDropdown, notify } from 'store';
import { errorMsgResolver } from '../../Documents/utils';
import Button from '@material-ui/core/Button';
import { ComboBoxBaseProps } from 'ui-kit/src/components/ComboBox/types';
import ChipInput from 'material-ui-chip-input';
import Papa from 'papaparse';

type Props = {
  user?: User;
  isOpen: boolean;
  handleClose: (arg0: boolean) => void;
  mode?: 'BULK' | 'CREATE' | 'EDIT';
};

const Container = styled('div')({
  display: 'flex',
  gap: '12px',
  flex: '1',
  flexDirection: 'column',
  justifyContent: 'space-around',
  margin: 'auto',
  padding: '0 8px',

  '& > div': {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',

    '&.createActionsWrapper': {
      gap: '8px',
      flexDirection: 'row',
      margin: '0 auto'
    }
  },

  '& input': {
    padding: '12px 8px'
  },

  '& .inputBox div': {
    borderRadius: '4px'
  },

  '& .bulk-wrapper': {
    '& .MuiFormControl-root > div': {
      maxHeight: '150px',
      overflowY: 'auto'
    },

    '& label': {
      display: 'flex',
      gap: '4px',

      '& > span': {
        marginLeft: 'auto'
      },
      '& > input': {
        padding: 0,
        width: '87px',
        overflow: 'hidden'
      }
    }
  }
});

const CreateUserFormType = (mode: Props['mode']) =>
  Yup.object().shape({
    ...(mode === 'CREATE' && { email: Yup.string().email().required() }),
    ...(mode === 'BULK' && {
      emails: Yup.array()
        .of(
          Yup.string()
            .email(({ path }) => {
              const index = path.split('[')[1].split(']')[0];
              if (!index) return 'At least one email is invalid';
              return `Email on position ${Number(index) + 1} is invalid`;
            })
            .required('Email is required')
        )
        .min(1, 'At least one email is required')
        .required('Email is required')
    }),
    tier: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string()
      })
      .required('Tier is required'),
    userType: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string()
    })
  });

const EditUserFormType = Yup.object().shape({
  email: Yup.string().email().required(),
  tier: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('Tier is required'),
  userType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string()
  }),
  marketplaceStatus: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string()
  }),
  dailyDownloadLimit: Yup.string().required(),
  totalDownloadLimit: Yup.string().required()
});

type UserData = {
  id?: number;
  email?: string;
  tier?: OptionType | undefined;
  userType: OptionType | undefined;
  is_active?: boolean;
  ticker?: OptionType[];
  type: OptionType | undefined;
  marketplaceStatus?: OptionType | undefined;
  dailyDownloadLimit?: number;
  totalDownloadLimit?: number;
};

type BulkUserData = Omit<UserData, 'email'> & { emails: string[] };

const handleCreatePreAccount = async ({ email, tier, userType, ticker, type }: UserData) => {
  const { hashCode, error } = await createPreAccount(
    email as string,
    tier?.value as string,
    ticker?.map((o) => o.value).join(',') as string,
    userType?.value as string,
    type?.value as string
  );

  if (error) {
    notify({
      message: error,
      severity: 'error',
      open: true
    });
  } else {
    notify({
      message: 'user created successfully',
      severity: 'success',
      open: true
    });
  }

  return hashCode;
};

const handleBulkCreatePreAccount = async ({
  emails,
  tier,
  userType,
  ticker,
  type
}: BulkUserData) => {
  const { errors } =
    (await bulkCreatePreAccount(
      emails,
      tier?.value as string,
      ticker?.map((o) => o.value).join(',') as string,
      userType?.value as string,
      type?.value as string
    )) ?? {};

  // If at least one email succeeded the request is considered a success
  const success = emails.length > errors?.length;

  if (errors?.length) {
    notify({
      message: [
        success ? 'Sign-up emails sent successfully, but some emails failed to send:\n' : '',
        ...errors.slice(0, 5).map((e) => {
          return `${e?.email}: ${e?.reason}\n`;
        })
      ],
      severity: success ? 'warning' : 'error',
      breakLine: true,
      open: true
    });
  } else {
    notify({
      message: 'Sign-up emails sent successfully',
      severity: 'success',
      open: true
    });
  }

  return success;
};

const handleUpdateUser = async ({
  id,
  tier,
  userType,
  is_active,
  type,
  marketplaceStatus,
  dailyDownloadLimit,
  totalDownloadLimit
}: UserData) => {
  const { success, error }: { success: boolean; error: string } = await updateUser(
    (id as unknown) as string,
    tier?.value as UserTier,
    userType?.value as UserType,
    is_active,
    type?.value,
    marketplaceStatus?.value,
    dailyDownloadLimit,
    totalDownloadLimit
  );

  if (success) {
    notify({
      message: 'user updated successfully',
      severity: 'success',
      open: true
    });
  } else {
    notify({
      message: error,
      severity: 'error',
      open: true
    });
  }
};

export const UserModal = ({ handleClose, isOpen, user, mode = 'CREATE' }: Props) => {
  const textRef = useRef<HTMLInputElement>(null);

  const [isEditingUser, setIsEditingUser] = useState(false);
  const [linkGenerated, setLinkGenerated] = useState<string | undefined>(undefined);

  const isEditMode = mode === 'EDIT';
  const isBulkMode = mode === 'BULK';

  const title = (() => {
    if (isEditMode) return 'Update User';

    if (isBulkMode) return 'Bulk Create Users Pre-account';

    return 'Create User Pre-account';
  })();

  const initialValues = (() => {
    if (mode === 'EDIT')
      return {
        id: user?.id,
        email: user?.email,
        tier: USER_TIER_OPTIONS.find((opt) => opt.value === user?.tier) as OptionType,
        userType: USER_TYPE_OPTIONS.find((opt) => opt.value === user?.user_type) as OptionType,
        is_active: user?.is_active,
        marketplaceStatus: USER_STATUS_OPTIONS.find(
          (opt) => opt.value === user?.marketplace_status
        ) as OptionType,
        dailyDownloadLimit: user?.daily_download_limit,
        totalDownloadLimit: user?.total_download_limit,
        type: PRODUCT_TYPE_OPTIONS.find((opt) => opt.value === user?.product_type) as OptionType
      };

    if (mode === 'BULK')
      return {
        emails: [],
        tier: undefined,
        type: undefined,
        userType: undefined,
        isTrialTier: true,
        ticker: []
      };

    return {
      email: '',
      tier: undefined,
      type: undefined,
      userType: undefined,
      isTrialTier: true,
      ticker: []
    };
  })() as UserData;

  const validationSchema = (() => {
    if (mode === 'EDIT') return EditUserFormType;

    return CreateUserFormType(mode);
  })();

  const handleSubmit = async (data: UserData | BulkUserData) => {
    if (mode === 'EDIT') {
      return await handleUpdateUser(data).then(() => {
        closeAndReset(true);
      });
    }

    if (mode === 'BULK') {
      return handleBulkCreatePreAccount(data as BulkUserData).then((success) => {
        if (success) {
          closeAndReset(true);
        }
      });
    }

    return await handleCreatePreAccount(data).then((hashCode) => {
      if (hashCode) {
        setLinkGenerated(
          `https://marketplace.daloopa.com/sign-up?email=${
            (data as UserData).email as string
          }&hashcode=${hashCode}`
        );
      }
    });
  };

  const formik = useFormik<UserData | BulkUserData>({
    initialValues,
    isInitialValid: false,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema,

    validate: ({ tier, userType, ticker, type }: UserData) => {
      if (!tier) {
        return { tier: 'Select a tier' };
      }
      if (mode !== 'EDIT' && tier.value === 'trial' && !ticker?.length) {
        return { ticker: 'Select a ticker' };
      }
      if (
        (tier.value === 'enterprise' || (mode !== 'EDIT' && tier?.value !== 'trial')) &&
        !userType?.value
      ) {
        return { userType: 'Select a user type' };
      }
      if (type === undefined) {
        return { type: 'Select a product type' };
      }
    },
    onSubmit: async (data) => {
      try {
        setIsEditingUser(true);

        await handleSubmit(data);
      } catch (error) {
        notify({
          message: errorMsgResolver(error as string),
          severity: 'error',
          open: true
        });
      }
      setIsEditingUser(false);
    }
  });

  const isTrialTier = formik.values.tier?.value === 'trial';

  const closeAndReset = (refresh = false) => {
    formik.resetForm();
    setLinkGenerated(undefined);
    handleClose(refresh);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(linkGenerated as string);
    textRef.current?.focus();
    notify({
      message: 'copied to clipboard',
      severity: 'success',
      open: true
    });
  };

  const handleSetValue = (field: string, value: ComboBoxBaseProps['value']) => {
    void formik.setFieldValue(field, value);
    void formik.setFieldTouched(field, true);
  };

  const handleAddEmail = (email: string) => {
    const values = (formik.values as BulkUserData).emails;
    if (values.length < 100) {
      const emails = [...(formik.values as BulkUserData).emails, email];
      void formik.setFieldValue('emails', emails);
    }
  };
  const handleDeleteEmail = (email: string) => {
    const emails = [...(formik.values as BulkUserData).emails].filter((e) => e !== email);
    void formik.setFieldValue('emails', emails);
  };

  const getErrors = (errors?: string | string[]) => {
    if (!errors) return '';

    const e = (Array.isArray(errors) ? errors : [errors]).slice(0, 3);

    return e.map((err, i) =>
      err?.replace(/\[\d+\]/g, ` ${i}`).concat(i === e.length - 1 ? '' : ', ')
    );
  };

  const handleCSVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const data = (results.data as { [key: string]: string }[])?.slice(0, 99) ?? [];
          const emails = Array.from(
            new Set(data.map((row) => Object.values(row)[0]).filter((e) => e))
          );
          void formik.setFieldValue('emails', emails);
        },
        error: (error) => {
          console.error(error);
          void formik.setFieldError('emails', 'Invalid CSV file');
        }
      });
    }
  };

  const productTypeSelector = (
    <div>
      <label htmlFor="type">Product Type</label>
      <ComboBox
        name="type"
        placeholder="Select Product Type"
        value={formik.values.type}
        setFieldValue={handleSetValue}
        options={PRODUCT_TYPE_OPTIONS}
        error={!!formik.errors.type}
        helperText={formik.errors.type}
      />
    </div>
  );

  const userTypeSelector = !isTrialTier && (
    <div>
      <label htmlFor="userType">User Type</label>
      <ComboBox
        name="userType"
        placeholder="Select userType"
        value={formik.values.userType}
        setFieldValue={handleSetValue}
        options={USER_TYPE_OPTIONS}
        error={!!formik.errors.userType}
        helperText={formik.errors.userType}
      />
    </div>
  );

  const tierSelector = (
    <div>
      <label htmlFor="tier">Tier</label>
      <ComboBox
        name="tier"
        placeholder="Select Tier"
        value={formik.values.tier}
        setFieldValue={handleSetValue}
        options={USER_TIER_OPTIONS}
        error={!!formik.errors.tier}
        helperText={formik.errors.tier}
      />
    </div>
  );

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      handleClose={() => closeAndReset(false)}
      width={'sm'}
      hideDialogActions={!isEditMode}
      handleOk={formik.handleSubmit}
      isSaveDisabled={!formik.isValid}
      minHeight={'35vh'}
      isLoading={isEditingUser}
    >
      <Container>
        {!isEditMode && (
          <>
            {!isBulkMode && (
              <div>
                <label htmlFor="email">Email Address</label>
                <TextField
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={(formik.values as UserData).email}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={!!(formik.errors as FormikErrors<UserData>).email}
                  helperText={(formik.errors as FormikErrors<UserData>).email}
                  className="inputBox"
                />
              </div>
            )}
            {isBulkMode && (
              <div className="bulk-wrapper">
                <label htmlFor="emails">
                  Email Addresses (Max: 100)
                  <span>CSV Upload: </span>
                  <input
                    type="file"
                    title="CSV Upload"
                    accept=".csv"
                    value=""
                    onChange={handleCSVUpload}
                  />
                </label>
                <ChipInput
                  value={(formik.values as BulkUserData).emails}
                  placeholder="Enter user emails here..."
                  onAdd={handleAddEmail}
                  onDelete={handleDeleteEmail}
                />
                <FormHelperText
                  error={!!(formik.errors as FormikErrors<BulkUserData>).emails?.length}
                >
                  {getErrors((formik.errors as FormikErrors<BulkUserData>).emails)}
                </FormHelperText>
              </div>
            )}

            {tierSelector}
            {productTypeSelector}
            {isTrialTier && (
              <div>
                <label htmlFor="ticker">Ticker</label>
                <TickerDropdown
                  formik={(formik as unknown) as FormikType}
                  selectedValue={formik.values.ticker}
                  isMulti
                  withLiveModels
                />
                <FormHelperText error={!!formik.errors.ticker?.length}>
                  {formik.errors.ticker}
                </FormHelperText>
              </div>
            )}
            {userTypeSelector}
            <div className="createActionsWrapper">
              {!isBulkMode && (
                <>
                  <Button
                    color="primary"
                    disabled={!formik.isValid || !!linkGenerated}
                    onClick={() => formik.handleSubmit()}
                  >
                    Create Signup link
                  </Button>

                  <Button color="primary" disabled={!linkGenerated} onClick={handleCopyToClipboard}>
                    Copy Signup link
                  </Button>
                </>
              )}
              {isBulkMode && (
                <Button
                  color="primary"
                  disabled={!formik.isValid}
                  onClick={() => formik.handleSubmit()}
                >
                  Send Signup Emails
                </Button>
              )}
              <Button
                color="primary"
                disabled={!formik.dirty}
                onClick={() => {
                  formik.resetForm();
                  setLinkGenerated(undefined);
                }}
              >
                Reset form
              </Button>
            </div>
          </>
        )}

        {isEditMode && (
          <>
            <div>
              <label htmlFor="email">Email Address</label>
              {(formik.values as UserData).email}
            </div>
            {productTypeSelector}
            <div>
              <label htmlFor="dailyDownloadLimit">Daily Limit</label>
              <TextField
                type={'number'}
                name="dailyDownloadLimit"
                placeholder="Daily Limit"
                InputLabelProps={{
                  shrink: true
                }}
                value={formik.values.dailyDownloadLimit}
                onChange={formik.handleChange}
                variant="outlined"
                error={!!formik.errors.dailyDownloadLimit}
                helperText={formik.errors.dailyDownloadLimit}
                inputProps={{ type: 'number', min: 0 }}
                className="inputBox"
              />
            </div>
            <div>
              <label htmlFor="totalDownloadLimit">Total Limit</label>
              <TextField
                type={'number'}
                name="totalDownloadLimit"
                placeholder="Total Limit"
                InputLabelProps={{
                  shrink: true
                }}
                value={formik.values.totalDownloadLimit}
                onChange={formik.handleChange}
                variant="outlined"
                error={!!formik.errors.totalDownloadLimit}
                helperText={formik.errors.totalDownloadLimit}
                inputProps={{ type: 'number', min: 0 }}
                className="inputBox"
              />
            </div>
            <div>
              <label htmlFor="markeplaceStatus">User Status</label>
              <ComboBox
                name="marketplaceStatus"
                placeholder="Select User Status"
                value={formik.values.marketplaceStatus}
                setFieldValue={handleSetValue}
                options={USER_STATUS_OPTIONS}
                error={!!formik.errors.marketplaceStatus}
                helperText={formik.errors.marketplaceStatus}
              />
            </div>
            {tierSelector}
            {userTypeSelector}
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.is_active}
                  onChange={() => {
                    formik.setFieldValue('is_active', !formik.values.is_active);
                    formik.setFieldTouched('is_active', true);
                  }}
                  color="primary"
                  name="active"
                />
              }
              label="Active"
            />
          </>
        )}
        {linkGenerated && (
          <TextField inputRef={textRef} variant="outlined" multiline value={linkGenerated} />
        )}
      </Container>
    </Dialog>
  );
};
