import React, { useEffect, useMemo, useRef, useState } from 'react';
import useStyles from './style';
import { Button } from '@material-ui/core';
import { EntityGrid } from 'store';
import { EntityGridRef } from 'store/src/EntityGrid/types';
import { getColumns } from './constants';
import { Add } from '@material-ui/icons';
import { getIndustryModals } from './services';
import { useAction } from '../Companies/hooks/useAction';
import { IndustryModal } from './types';
import { IndustryModelModal } from './modals/IndustryModelModal';

const IndustryModels: React.FC = () => {
  const classes = useStyles();
  const entityRef = useRef<EntityGridRef>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isIndustryModalOpened, setIsIndustryModalOpened] = useState(false);
  const [currentIndustryModel, setCurrentIndustryModel] = useState<IndustryModal | undefined>(
    undefined
  );

  const columns = useMemo(
    () =>
      getColumns((model) => {
        setCurrentIndustryModel(model);
        setIsIndustryModalOpened(true);
      }),
    []
  );

  const actions = useAction();

  useEffect(() => {
    actions.loadIndustriesAction();
  }, []);

  const onSuccess = () => {
    if (entityRef.current) {
      entityRef.current.refresh();
      setCurrentIndustryModel(undefined);
    }
  };

  return (
    <>
      {isIndustryModalOpened && (
        <IndustryModelModal
          isOpen={isIndustryModalOpened}
          handleClose={() => {
            setIsIndustryModalOpened(false);
            setCurrentIndustryModel(undefined);
          }}
          onSuccess={onSuccess}
          id={currentIndustryModel?.id}
          mode={currentIndustryModel ? 'edit' : 'create'}
        />
      )}
      <EntityGrid
        ref={entityRef}
        loading={isLoading}
        entityName="industry models"
        rows={[]}
        isServerHandled={false}
        columns={columns}
        searchableColumns={['industry']}
        placeholder="Search Industry..."
        isGqlAPI={true}
        isRowSelectable={() => false}
        graphqlService={(page: number, pageSize: number, filters: { industry: string }) =>
          getIndustryModals(filters.industry?.toUpperCase())
        }
        actionContent={
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setIsIndustryModalOpened(true)}
            className={classes.primaryBtn}
          >
            Create Industry Model
          </Button>
        }
      />
    </>
  );
};

export default IndustryModels;
